import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export function TextInput(props) {
  const {
    type,
    label,
    placeholder,
    name,
    onChange,
    error,
    value,
    autofocus,
    disabled,
    required = false,
    inputClassName,
    description,
    icon = false,
    min,
  } = props;

  const [showIcon, setShowIcon] = useState(false);

  const labelClasses = classNames(
    'block tracking-wide text-sm font-medium mb-2',
    { 'text-gray-700': !error },
    { 'text-red-400': error }
  );

  const inputClasses = classNames(
    'appearance-none rounded w-full py-3 px-3 text-gray-700 bg-blue-50 border border-gray-200 leading-tight focus:outline-none focus:border-gray-400 focus:ring-blue focus:ring-offset-blue',
    { border: error },
    { 'border-red-400': error },
    `${inputClassName}`
  );

  const handleIconShow = (e) => {
    e.preventDefault();
    setShowIcon(!showIcon);
  };

  return (
    <>
      {label && (
        <div className="flex items-center justify-between">
          <label className={labelClasses} htmlFor={name}>
            {label}
            {required && <span className="text-red-400 text-xs ml-1">*</span>}
          </label>
          {icon && (
            <button
              type="button"
              className="appearance-none"
              onClick={handleIconShow}
              tabIndex="-1"
            >
              <FontAwesomeIcon
                icon={showIcon ? faEyeSlash : faEye}
                className="text-sm animated text-gray-700"
              />
            </button>
          )}
        </div>
      )}
      <input
        className={inputClasses}
        name={name}
        id={name}
        type={icon ? (showIcon ? 'text' : 'password') : type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
        autoFocus={autofocus}
        required={required}
        min={min}
      />
      {description && (
        <span className="text-gray-500 text-sm mt-1 block">{description}</span>
      )}
      {error && <span className="text-red-400 text-xs block">{error}</span>}
    </>
  );
}

TextInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  required: PropTypes.bool,
  inputClassName: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.bool,
};
