import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function TextArea(props) {
  const {
    label,
    placeholder,
    name,
    error,
    rows,
    onChange,
    value,
    defaultValue,
    className,
    onKeyUp,
    onKeyDownHandler,
    onBlur,
    disabled,
    required = false,
  } = props;

  const labelClasses = classNames(
    'block',
    'tracking-wide',
    'text-sm',
    'font-medium',
    'mb-1',
    { 'text-gray-700': !error },
    { 'text-red-400': error }
  );

  const inputClasses = classNames(
    'appearance-none',
    'rounded',
    'w-full',
    'py-2',
    'px-3',
    'text-gray-700',
    'bg-blue-50',
    'border',
    'border-gray-200',
    'leading-tight',
    'focus:outline-none',
    'focus:border-gray-400',
    { border: error },
    { 'border-red-400': error }
  );

  return (
    <>
      <label className={labelClasses} htmlFor="name">
        {label}
        {required && <span className="text-red-400 text-xs ml-1">*</span>}
      </label>
      <textarea
        className={`${inputClasses} ${className}`}
        name={name}
        id={name}
        placeholder={placeholder}
        onChange={onChange}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDownHandler}
        onBlur={onBlur}
        rows={rows}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        required={required}
      />
      {error && <span className="text-red-400 text-xs">{error}</span>}
    </>
  );
}

TextArea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  rows: PropTypes.number,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  onKeyUp: PropTypes.func,
  onKeyDownHandler: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
