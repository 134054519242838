import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { parseUnderScore } from '../../../../helpers/text';

export function Select(props) {
  const {
    name,
    error,
    label,
    options,
    onChange,
    selected,
    required = false,
  } = props;

  const labelClasses = classNames(
    'block',
    'tracking-wide',
    'text-sm',
    'font-medium',
    'mb-2',
    { 'text-gray-700': !error },
    { 'text-red-400': error }
  );

  const inputClasses = classNames(
    'block appearance-none w-full bg-blue-50 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 capitalize',
    { border: error },
    { 'border-red-400': error }
  );

  const parseOption = (str) => {
    if (typeof str !== 'string') {
      return str;
    }

    return str.replace(/_/g, ' ').replace(/-/g, ' ');
  };

  return (
    <>
      {label && (
        <label className={labelClasses} htmlFor={name}>
          {label}
          {required && <span className="text-red-400 text-xs ml-1">*</span>}
        </label>
      )}
      <div className="flex relative w-full">
        <select
          name={name}
          id={name}
          onChange={onChange}
          className={inputClasses}
          defaultValue={selected}
          value={selected}
        >
          <option value="">Select {parseUnderScore(name)}</option>
          {options &&
            options.map((option, index) => (
              <option
                value={
                  typeof option === 'string' ? option.toLowerCase() : option
                }
                key={`option${option}-${index}`}
              >
                {parseOption(option)}
              </option>
            ))}
        </select>
      </div>
      {error && <span className="text-red-400 text-xs">{error}</span>}
    </>
  );
}

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};
