import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function CheckBox(props) {
  const {
    name,
    label,
    error,
    value,
    onChange,
    checked,
    description,
    disabled,
  } = props;

  const classList = classNames(
    'form-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer',
    { border: error },
    { 'border-red-400': error }
  );
  const inputClasses = props.inputClasses ? props.inputClasses : classList;

  const labelClasses = props.labelClasses
    ? props.labelClasses
    : classNames(
        'text-sm text-gray-600 leading-none ml-1 mt-[2px] cursor-pointer font-medium'
        // eslint-disable-next-line no-mixed-spaces-and-tabs
      );

  return (
    <label htmlFor={name} className="cursor-pointer">
      <div className="flex items-start">
        <input
          className={inputClasses}
          name={name}
          id={name}
          type="checkbox"
          onChange={onChange}
          checked={checked}
          value={value || name}
          disabled={disabled}
        />
        <span className={labelClasses}>{label}</span>
      </div>
      {error && (
        <span className="text-red-400 text-xs flex-grow w-full">{error}</span>
      )}
      {description && (
        <span className="text-gray-500 text-sm mt-1">{description}</span>
      )}
    </label>
  );
}

CheckBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  inputClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  error: PropTypes.string,
  description: PropTypes.string,
};
