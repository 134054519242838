import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';

export function RadioInput(props) {
  const {
    name,
    label,
    error,
    options,
    onChange,
    selected,
    flexDirection = 'row',
    design = 'normal',
  } = props;

  const labelClasses = classNames(
    'block',
    'tracking-wide',
    'text-sm',
    'font-medium',
    'mb-1',
    { 'text-gray-700': !error },
    { 'text-red-400': error }
  );

  const inputClasses = classNames(
    'form-checkbox h-4 w-4 transition duration-150 ease-in-out',
    { border: error },
    { 'border-red-400': error }
  );

  const wrapperClasses = classNames(
    'flex',
    { 'flex-col': flexDirection === 'column' },
    { 'flex-row': flexDirection === 'row' }
  );

  return (
    <>
      {design === 'normal' && (
        <>
          {label && <p className={labelClasses}>{label}</p>}
          <div className={wrapperClasses}>
            {options &&
              options.map((option, index) => (
                <div
                  className={`block ${flexDirection === 'row' ? 'mr-5' : ''}`}
                  key={`radio${name}-${index}`}
                >
                  <label
                    className="cursor-pointer"
                    id={`${option.value}-label`}
                  >
                    <input
                      className={inputClasses}
                      name={name}
                      id={name}
                      type="radio"
                      value={option.value}
                      onChange={onChange}
                      checked={selected == option.value}
                    />
                    <span className="text-sm text-gray-600 font-medium ml-1">
                      {option.label}
                    </span>
                  </label>
                </div>
              ))}
          </div>
          {error && <span className="text-red-400 text-xs">{error}</span>}
        </>
      )}
      {design === 'bold' && (
        <>
          {label && <p className={labelClasses}>{label}</p>}
          <div className={wrapperClasses}>
            {options &&
              options.map((option, index) => (
                <div
                  className={`flex ${
                    flexDirection === 'row' ? 'mr-5' : 'w-full mb-2'
                  } `}
                  key={`radio${name}-${index}`}
                >
                  <label
                    className={`cursor-pointer w-full px-3 py-2 rounded ${
                      selected == option.value
                        ? 'bg-blue text-white'
                        : 'bg-white shadow-sm border text-gray-600'
                    }`}
                    id={`${option.value}-label`}
                  >
                    <input
                      className="hidden"
                      name={name}
                      id={name}
                      type="radio"
                      value={option.value}
                      onChange={onChange}
                      checked={selected == option.value}
                    />
                    <div className="w-full flex items-center">
                      {selected == option.value && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="mr-1"
                          size="1x"
                        />
                      )}
                      {selected != option.value && (
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="mr-1 text-gray-300"
                          size="1x"
                        />
                      )}
                      <span className="text-sm font-medium ml-1 text-current">
                        {option.label}
                      </span>
                    </div>
                  </label>
                </div>
              ))}
          </div>
          {error && <span className="text-red-400 text-xs">{error}</span>}
        </>
      )}
    </>
  );
}

RadioInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.string,
  flexDirection: PropTypes.string,
  error: PropTypes.string,
  design: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
